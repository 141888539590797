import { createRouter, createWebHistory } from "vue-router";

import LogInPage from "@/pages/AuthenticationPage.vue";

const MainPage = () => import("@/pages/MainPage.vue");

const DataPage = () => import("@/pages/Data/DataPage.vue");
const UsersPage = () => import("@/pages/Data/UsersPage.vue");
const GuestsPage = () => import("@/pages/Data/GuestsPage.vue");
const GroupsPage = () => import("@/pages/Data/GroupsPage.vue");
const SegmentsPage = () => import("@/pages/Data/SegmentsPage.vue");
const EventsPage = () => import("@/pages/Data/EventsPage.vue");
const ChatsPage = () => import("@/pages/Data/ChatsPage.vue");
const VideosPage = () => import("@/pages/Data/VideosPage.vue");
const ExpertsPage = () => import("@/pages/Data/ExpertsPage.vue");
const CollaborationsPage = () => import("@/pages/Data/CollaborationsPage.vue");
const RecommendationsPage = () =>
  import("@/pages/Data/RecommendationsPage.vue");
const CodexPage = () => import("@/pages/Data/CodexPage.vue");
const InitiativePage = () => import("@/pages/Data/InitiativePage.vue");
const ExpertisesPage = () => import("@/pages/Data/ExpertisesPage.vue");

const ActivityPage = () => import("@/pages/Activity/ActivityPage.vue");
const RegistrationsPage = () => import("@/pages/Activity/InvitesPage.vue");
const TasksPage = () => import("@/pages/Activity/TasksPage.vue");
const ContributionsPage = () =>
  import("@/pages/Activity/ContributionsPage.vue");
const CharitysPage = () => import("@/pages/Activity/CharitysPage.vue");
const SupportsPage = () => import("@/pages/Activity/SupportsPage.vue");
const PossibilitysPage = () => import("@/pages/Activity/PossibilitysPage.vue");

const AnalyticsPage = () => import("@/pages/Analytics/AnalyticsPage.vue");
const ActivityPage_Analytics = () =>
  import("@/pages/Analytics/ActivityPage.vue");
const AttendanceGroupPage = () =>
  import("@/pages/Analytics/AttendanceGroupPage.vue");
const InvolvementPage = () => import("@/pages/Analytics/InvolvementPage.vue");
const AttendancePage = () => import("@/pages/Analytics/AttendancePage.vue");
const AnalyticContributionsPage = () =>
  import("@/pages/Analytics/ContributionsPage.vue");
const AnalyticSupportsPage = () => import("@/pages/Analytics/SupportsPage.vue");
const AnalyticCharitysPage = () => import("@/pages/Analytics/CharitysPage.vue");
const AnalyticPossibilitysPage = () =>
  import("@/pages/Analytics/PossibilitysPage.vue");
const ParticipantFlowPage = () =>
  import("@/pages/Analytics/ParticipantFlowPage.vue");

import ProcessesPage from "@/pages/Processes/ProcessesPage.vue";
import ProcessPage from "@/pages/Processes/ProcessPage.vue";

const ResetPasswordPage = () => import("@/pages/App/ResetPasswordPage.vue");
const GoToAppPage = () => import("@/pages/App/GoToAppPage.vue");
const GoToAppSecondPage = () => import("@/pages/App/GoToAppSecondPage.vue");
const GoToAppThirdPage = () => import("@/pages/App/GoToAppThirdPage.vue");
const RegistrationManagerPage = () =>
  import("@/pages/App/RegistrationManagerPage.vue");

const FavoritesPage = () => import("@/pages/Favorites/FavoritesPage.vue");

const HomePage = () => import("@/pages/HomePage.vue");

const IntegrationsPage = () =>
  import("@/pages/Integrations/IntegrationsPage.vue");
const IntegrationPage = () =>
  import("@/pages/Integrations/IntegrationPage.vue");

const LayoutPage = () => import("@/pages/LayoutPage.vue");

export default createRouter({
  mode: "hash",
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  history: createWebHistory(),
  routes: [
    {
      path: "/:id?",
      name: "GoToAppPage",
      component: GoToAppPage,
    },
    {
      path: "/:pathId?/:id?",
      name: "GoToAppSecondPage",
      component: GoToAppSecondPage,
    },
    {
      path: "/:pathId?/:pathId2?/:id?",
      name: "GoToAppThirdPage",
      component: GoToAppThirdPage,
    },
    {
      path: "/",
      redirect: "home",
      name: "MainPage",
      component: MainPage,
      children: [
        {
          path: "data",
          redirect: { name: "UsersPage", params: { id: "active" } },
          name: "DataPage",
          component: DataPage,
          children: [
            {
              path: "profiles/:id?",
              name: "UsersPage",
              component: UsersPage,
            },
            {
              path: "guests/:id?",
              name: "GuestsPage",
              component: GuestsPage,
            },
            {
              path: "groups",
              name: "GroupsPage",
              component: GroupsPage,
            },
            {
              path: "segments",
              name: "SegmentsPage",
              component: SegmentsPage,
            },
            {
              path: "events/:id?",
              name: "EventsPage",
              component: EventsPage,
            },
            {
              path: "chats",
              name: "ChatsPage",
              component: ChatsPage,
            },
            {
              path: "videos",
              name: "VideosPage",
              component: VideosPage,
            },
            {
              path: "expert",
              name: "ExpertsPage",
              component: ExpertsPage,
            },
            {
              path: "collaborations",
              name: "CollaborationsPage",
              component: CollaborationsPage,
            },
            {
              path: "recommendations",
              name: "RecommendationsPage",
              component: RecommendationsPage,
            },
            {
              path: "codex",
              name: "CodexPage",
              component: CodexPage,
            },
            {
              path: "initiative/:id?",
              name: "InitiativePage",
              component: InitiativePage,
            },
            {
              path: "expertises",
              name: "ExpertisesPage",
              component: ExpertisesPage,
            },
          ],
        },
        {
          path: "activity",
          redirect: {
            name: "RegistrationsPage",
            params: { id: "registration_is_open" },
          },
          name: "ActivityPage",
          component: ActivityPage,
          children: [
            {
              path: "registrations/:id?",
              name: "RegistrationsPage",
              component: RegistrationsPage,
            },
            {
              path: "users",
              redirect: { name: "RegistrationsPage" },
              name: "TasksPage",
              component: TasksPage,
            },
            {
              path: "contributions",
              name: "ContributionsPage",
              component: ContributionsPage,
            },
            {
              path: "charity/:id?",
              name: "CharitysPage",
              component: CharitysPage,
            },
            {
              path: "supports",
              name: "SupportsPage",
              component: SupportsPage,
            },
            {
              path: "opportunities",
              name: "PossibilitysPage",
              component: PossibilitysPage,
            },
          ],
        },
        {
          path: "analytics",
          redirect: { name: "ActivityPage_Analytics" },
          name: "AnalyticsPage",
          component: AnalyticsPage,
          children: [
            {
              path: "activity",
              name: "ActivityPage_Analytics",
              component: ActivityPage_Analytics,
            },
            {
              path: "attendance_group",
              name: "AttendanceGroupPage",
              component: AttendanceGroupPage,
            },
            {
              path: "engagement",
              name: "InvolvementPage",
              component: InvolvementPage,
            },
            {
              path: "attendance/:id?",
              name: "AttendancePage",
              component: AttendancePage,
            },
            {
              path: "contributions",
              name: "AnalyticContributionsPage",
              component: AnalyticContributionsPage,
            },
            {
              path: "supports/:id?",
              name: "AnalyticSupportsPage",
              component: AnalyticSupportsPage,
            },
            {
              path: "charity",
              name: "AnalyticCharitysPage",
              component: AnalyticCharitysPage,
            },
            {
              path: "opportunities",
              name: "AnalyticPossibilitysPage",
              component: AnalyticPossibilitysPage,
            },
            {
              path: "participant_flow",
              name: "ParticipantFlowPage",
              component: ParticipantFlowPage,
            },
          ],
        },
        {
          path: "favorites",
          name: "FavoritesPage",
          component: FavoritesPage,
          children: [
            {
              path: "profiles/:id?",
              name: "UsersPage_Favorite",
              component: UsersPage,
            },
            {
              path: "groups/:id?",
              name: "GroupsPage_Favorite",
              component: GroupsPage,
            },
            {
              path: "segments",
              name: "SegmentsPage_Favorite",
              component: SegmentsPage,
            },
            {
              path: "events/:id?",
              name: "EventsPage_Favorite",
              component: EventsPage,
            },
            {
              path: "chats",
              name: "ChatsPage_Favorite",
              component: ChatsPage,
            },
            {
              path: "videos",
              name: "VideosPage_Favorite",
              component: VideosPage,
            },
            {
              path: "expert",
              name: "ExpertsPage_Favorite",
              component: ExpertsPage,
            },
            {
              path: "collaborations",
              name: "CollaborationsPage_Favorite",
              component: CollaborationsPage,
            },
            {
              path: "recommendations",
              name: "RecommendationsPage_Favorite",
              component: RecommendationsPage,
            },
            {
              path: "codex",
              name: "CodexPage_Favorite",
              component: CodexPage,
            },
            {
              path: "initiative/:id?",
              name: "InitiativePage_Favorite",
              component: InitiativePage,
            },
            {
              path: "registrations/:id?",
              name: "RegistrationsPage_Favorite",
              component: RegistrationsPage,
            },
            {
              path: "contributions",
              name: "ContributionsPage_Favorite",
              component: ContributionsPage,
            },
            {
              path: "charity/:id?",
              name: "CharitysPage_Favorite",
              component: CharitysPage,
            },
            {
              path: "supports",
              name: "SupportsPage_Favorite",
              component: SupportsPage,
            },
            {
              path: "opportunities",
              name: "PossibilitysPage_Favorite",
              component: PossibilitysPage,
            },
            {
              path: "activity",
              name: "ActivityPage_Analytics_Favorite",
              component: ActivityPage_Analytics,
            },
            {
              path: "attendance/:id?",
              name: "AttendancePage_Favorite",
              component: AttendancePage,
            },
            {
              path: "attendance_group",
              name: "AttendanceGroupPage_Favorite",
              component: AttendanceGroupPage,
            },
            {
              path: "engagement",
              name: "InvolvementPage_Favorite",
              component: InvolvementPage,
            },
            {
              path: "contributions",
              name: "AnalyticContributionsPage_Favorite",
              component: AnalyticContributionsPage,
            },
            {
              path: "supports/:id?",
              name: "AnalyticSupportsPage_Favorite",
              component: AnalyticSupportsPage,
            },
            {
              path: "charity",
              name: "AnalyticCharitysPage_Favorite",
              component: AnalyticCharitysPage,
            },
            {
              path: "possibility",
              name: "AnalyticPossibilitysPage_Favorite",
              component: AnalyticPossibilitysPage,
            },
            {
              path: "participant_flow",
              name: "ParticipantFlowPage_Favorite",
              component: ParticipantFlowPage,
            },
          ],
        },
        {
          path: "processes",
          name: "ProcessesPage",
          component: ProcessesPage,
          children: [
            {
              path: ":id?",
              name: "ProcessPage",
              component: ProcessPage,
            },
          ],
        },
        {
          path: "integrations",
          name: "IntegrationsPage",
          component: IntegrationsPage,
        },
        {
          path: "integration/:integrationId?",
          name: "IntegrationPage",
          component: IntegrationPage,
        },
        {
          path: "home",
          name: "HomePage",
          component: HomePage,
        },
      ],
    },
    {
      path: "/login",
      name: "LogInPage",
      component: LogInPage,
    },
    {
      path: "/account/reset_password/:id?",
      name: "ResetPasswordPage",
      component: ResetPasswordPage,
    },
    {
      path: "/account/registration_manager/:id?",
      name: "RegistrationManagerPage",
      component: RegistrationManagerPage,
    },
    {
      path: "/layouts",
      name: "LayoutPage",
      component: LayoutPage,
    },
  ],
});
